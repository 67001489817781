import React from "react";
import "./index.css"
import Header from "./components/header/Header";
import Nav from './components/nav/Nav';
import About from './components/about/About'
import Experience from "./components/experience/Experience";
import Portfolio from "./components/portfolio/Portfolio";
import Contact from "./components/contact/Contact";
import Footer from "./components/footer/Footer";


function App() {

    return (
        <React.Fragment>
            <Header />
            <Nav />
            <About />
            <Experience />
            <Portfolio />
            <Contact />
            <Footer />
        </React.Fragment>
    );
}

export default App;